import cosmologuesImg from '~assets/img/cosmologues.png?w=300&format=avif&quality=100&imagetoolsurl';
import psychicsImg from '~assets/img/psychics.png?w=300&format=avif&quality=100&imagetoolsurl';
import terreImg from '~assets/img/terre.png?w=300&format=avif&quality=100&imagetoolsurl';
import feuImg from '~assets/img/feu.png?w=300&format=avif&quality=100&imagetoolsurl';
import maruosImg from '~assets/img/maruos.png?w=300&format=avif&quality=100&imagetoolsurl';
import spectrauxImg from '~assets/img/spectraux.png?w=300&format=avif&quality=100&imagetoolsurl';
import morphoImg from '~assets/img/morpho.png?w=300&format=avif&quality=100&imagetoolsurl';
import eauImg from '~assets/img/eau.png?w=300&format=avif&quality=100&imagetoolsurl';
import airImg from '~assets/img/air.png?w=300&format=avif&quality=100&imagetoolsurl';
import herboImg from '~assets/img/herboristes.png?w=300&format=avif&quality=100&imagetoolsurl';
import type { GlossaryItemType } from './glossary';

export enum ClansCategoryEnum {
  WATER = 'WATER',
  FIRE = 'FIRE',
  EARTH = 'EARTH',
  AIR = 'AIR',
  MORPHOS = 'MORPHOS',
  MARUOS = 'MARUOS',
  SPECTRAL = 'SPECTRAL',
  COSMOLOGIST = 'COSMOLOGIST',
  HERBALISTS = 'HERBALISTS',
  PSYCHICS = 'PSYCHICS',
}

export const allClans: Record<ClansCategoryEnum, GlossaryItemType> = {
  MARUOS: {
    name: 'clans.maruos.displayName',
    description: 'clans.maruos.description',
    img300: maruosImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  SPECTRAL: {
    name: 'clans.spectral.displayName',
    description: 'clans.spectral.description',
    img300: spectrauxImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  FIRE: {
    name: 'clans.fire.displayName',
    description: 'clans.fire.description',
    img300: feuImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  EARTH: {
    name: 'clans.earth.displayName',
    description: 'clans.earth.description',
    img300: terreImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  WATER: {
    name: 'clans.water.displayName',
    description: 'clans.water.description',
    img300: eauImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  AIR: {
    name: 'clans.air.displayName',
    description: 'clans.air.description',
    img300: airImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  MORPHOS: {
    name: 'clans.morphos.displayName',
    description: 'clans.morphos.description',
    img300: morphoImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  HERBALISTS: {
    name: 'clans.herbalists.displayName',
    description: 'clans.herbalists.description',
    img300: herboImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  COSMOLOGIST: {
    name: 'clans.cosmologist.displayName',
    description: 'clans.cosmologist.description',
    img300: cosmologuesImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
  PSYCHICS: {
    name: 'clans.psychics.displayName',
    description: 'clans.psychics.description',
    img300: psychicsImg,
    drawBy: 'drawers.drawByJulieRabischung',
  },
};
