import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import type { QuestionPossibility, QuizStorage } from '~routes/Quiz/data/data';
import type { Theme } from '@mui/material';

interface Props {
  state: QuizStorage<string>;
}

function QuizResults({ state }: Props) {
  // Setup translate
  const { t } = useTranslation();

  const validN = state.answers.reduce((r, a) => r + a.answer.value, 0);
  const len = state.questions.length;

  return (
    <>
      <Typography variant="h4" sx={{ margin: '20px 0' }}>
        {t('quiz.results')}: {validN} / {state.questions.length}
      </Typography>
      {state.answers.map((it, i) => (
        <div
          key={it.answer.answer}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            margin: '20px 0',
          }}
        >
          <Typography sx={{ marginBottom: '5px' }}>
            {i + 1}) {t(it.question.question)}
          </Typography>
          {it.question.possibilities.map((it2) => (
            <CheckedOption possibility={it2} answer={it.answer} />
          ))}
          {len !== i + 1 && <Divider variant="middle" flexItem sx={{ marginTop: '10px' }} />}
        </div>
      ))}
    </>
  );
}

interface CheckedOptionProps {
  possibility: QuestionPossibility<string>;
  answer: QuestionPossibility<string>;
}

const typographyStyle = { display: 'inline-flex', alignItems: 'center', verticalAlign: 'middle', marginLeft: '-11px' };

function CheckedOption({ possibility, answer }: CheckedOptionProps) {
  // Setup translate
  const { t } = useTranslation();

  if (possibility.answer === answer.answer) {
    const color = answer.value === 1 ? 'success' : 'error';

    return (
      <Typography
        sx={(theme: Theme) => ({
          color: theme.palette[color].main,
          ...typographyStyle,
        })}
      >
        <Radio checked value onChange={() => {}} color={color} />
        {t(possibility.answer)}
      </Typography>
    );
  }

  return (
    <Typography
      sx={(theme: Theme) => ({
        color: possibility.value === 1 ? theme.palette.success.main : theme.palette.text.primary,
        ...typographyStyle,
      })}
    >
      <Radio
        color={possibility.value === 1 ? 'success' : 'default'}
        value={false}
        checked={false}
        sx={{
          color: (theme: Theme) => (possibility.value === 1 ? theme.palette.success.main : theme.palette.text.primary),
          '&.Mui-checked': {
            color: (theme: Theme) =>
              possibility.value === 1 ? theme.palette.success.main : theme.palette.text.primary,
          },
        }}
        onChange={() => {}}
      />
      {t(possibility.answer)}
    </Typography>
  );
}

export default QuizResults;
