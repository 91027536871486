import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { allClans, type ClansCategoryEnum } from '~models/clans';
import type { QuizStorage } from '~routes/Quiz/data/data';
import Divider from '@mui/material/Divider';

interface Props {
  state: QuizStorage<ClansCategoryEnum>;
}

const findKeyWithMaxValue = (obj: Record<ClansCategoryEnum, number>): ClansCategoryEnum =>
  Object.entries(obj).reduce((maxEntry, currentEntry) =>
    currentEntry[1] > maxEntry[1] ? currentEntry : maxEntry,
  )[0] as ClansCategoryEnum;

function QuizResults({ state }: Props) {
  // Setup translate
  const { t } = useTranslation();

  // Compute results
  const res: Record<ClansCategoryEnum, number> = {
    AIR: 0,
    COSMOLOGIST: 0,
    EARTH: 0,
    FIRE: 0,
    HERBALISTS: 0,
    MARUOS: 0,
    MORPHOS: 0,
    PSYCHICS: 0,
    SPECTRAL: 0,
    WATER: 0,
  };

  // Loop
  state.answers.forEach((it) => {
    const cat = it.answer.category as ClansCategoryEnum;
    res[cat] += it.answer.value;
  });

  // Find max
  const selectedClan = findKeyWithMaxValue(res);

  // Get Clans
  const clan = allClans[selectedClan];

  return (
    <div style={{ textAlign: 'center', margin: '10px' }}>
      <Typography variant="h4">{t(clan.name)}</Typography>
      <div style={{ padding: '20px' }}>
        <Divider />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          margin: 'auto',
          alignItems: 'center',
        }}
      >
        <img
          src={clan.img300}
          alt={t(clan.name)}
          style={{ maxHeight: 300, maxWidth: 300, width: '100%' }}
          loading="lazy"
        />
      </div>
      <Typography
        sx={{
          textAlign: 'center',
          fontStyle: 'italic',
          fontSize: '0.8rem',
          marginBottom: '10px',
        }}
      >
        {t(clan.drawBy)}
      </Typography>
      <Typography style={{ textAlign: 'justify' }}>{t(clan.description)}</Typography>
    </div>
  );
}

export default QuizResults;
