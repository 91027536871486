import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Answer, clansQuestions, Question, QuizStorage } from '~routes/Quiz/data/data';
import { mdiReload } from '@mdi/js';
import { ClansCategoryEnum } from '~models/clans';
import QuestionDisplay from '../QuestionDisplay';
import QuizResults from './components/QuizResults';

function WhichClansQuiz() {
  // Setup translate
  const { t } = useTranslation();

  const [state, setState] = useState<QuizStorage<ClansCategoryEnum>>({ questions: [], answers: [] });

  if (state.questions.length === 0) {
    return (
      <>
        <CardHeader title={t('quiz.clans.title')} />
        <CardContent>
          <Button
            onClick={() => {
              setState({
                ...state,
                questions: clansQuestions,
              });
            }}
          >
            {t('quiz.clans.start')}
          </Button>
        </CardContent>
      </>
    );
  }

  let question: Question<ClansCategoryEnum> | undefined;
  if (state.answers.length <= state.questions.length) {
    question = state.questions[state.answers.length];
  }

  return (
    <>
      <CardHeader
        title={t('quiz.clans.title')}
        action={
          <Tooltip title={t('common.reset')}>
            <IconButton
              sx={{ marginLeft: '10px' }}
              onClick={() => {
                setState({ answers: [], questions: [] });
              }}
            >
              <SvgIcon>
                <path d={mdiReload} />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        }
        sx={{ paddingBottom: 0 }}
      />
      <CardContent sx={{ paddingTop: 0 }}>
        {question && (
          <>
            <Typography variant="caption" sx={{ padding: 0 }}>
              {state.answers.length + 1} / {state.questions.length}
            </Typography>
            <div style={{ margin: '10px 0' }}>
              <QuestionDisplay
                question={question}
                handleNext={(a: Answer<ClansCategoryEnum>) => {
                  setState({
                    ...state,
                    answers: [...state.answers, a],
                  });
                }}
              />
            </div>
          </>
        )}
        {!question && <QuizResults state={state} />}
      </CardContent>
    </>
  );
}

export default WhichClansQuiz;
